import { Menu, message } from 'antd'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { RootState, resetAuthData } from '../../store/authSlice';
import { HomeOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import userPoolCognitoService from '../../services/userPoolService';
import { FC } from 'react';


interface SidebarProps {
  navigate: NavigateFunction
}

const Sidebar: FC<SidebarProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = useSelector((state: RootState) => state.auth); 
  const logout = async () => {
    try {
      if(!access_token) {
        message.error("Access Token is null")
        return;
      }
      const response = await userPoolCognitoService.logout(access_token);
      
      if (response?.data?.$metadata.httpStatusCode === 200) {
        message.success("User Logged out successfully")
        localStorage.clear();
        dispatch(resetAuthData())
        navigate("/")
      } else {
        message.error("Something went wrong")
      }

    } catch (error: any) {      
      if(error.message === 'Access Token has expired') {
        localStorage.clear();
        dispatch(resetAuthData())
        navigate("/")
      } else if (error.message === 'Access Token has been revoked') {
        localStorage.clear();
        dispatch(resetAuthData())
        navigate("/")
      } else {
        message.error("Something went wrong")
      }      
    }
  }
  return (
    <div>
      
      <Menu onClick={({key}) => {
        navigate(key)
      }} defaultSelectedKeys={[window.location.pathname]}      
               
      >
        <Menu.Item key="/dashboard" ><HomeOutlined /> Home</Menu.Item>
        
        <Menu.Item key="/profile"><UserOutlined/> Profile</Menu.Item>
        <Menu.Item key="/" onClick={logout} danger><LogoutOutlined/> Logout</Menu.Item>
      </Menu> 
     
    </div>
  )
}

export default Sidebar
